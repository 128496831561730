/** @jsx jsx */
import { jsx, Flex, Box, Heading, Text, Styled } from "theme-ui"
import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Wide from "../components-styled/Wide"
import PictureSet from "../components/PictureSet"
import imgSet10 from "../imgsets/imgset10"

const Spacer = () => <span sx={{ mx: 1 }} />

const Blogs = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  let blogs = data.allMdx.group[0].edges
  if (data.allMdx.group.length === 2) {
    const pins = data.allMdx.group[1].edges
    blogs = [...pins, ...blogs]
  }

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Blog" />

      <Wide>
        <PictureSet imgSet={imgSet10} />
      </Wide>

      <Heading
        as="h1"
        sx={{ fontSize: [8, 8, 8, 8, 1], color: "heading", mt: 4, mb: 3 }}
      >
        Blog
      </Heading>

      <Text>
        Hier berichten wir querbeet über interessante Themen im Kontext von
        Gründung und Startups im Umfeld des hgnc. Wenn du selbst gerne mal über
        ein interessantes Thema im Kontext Startup berichten oder wenn du über
        einen Prozess im Rahmen (d)einer Gründung schreiben möchtest, sende
        bitte eine E-Mail an <a href="mailto://hgnc@hgnc.de">hgnc@hgnc.de</a>.
      </Text>

      {blogs.map(({ node }) => {
        const title = node.frontmatter.title
        const anchor = node.fields.slug
          .replace(/\/blog\//g, "")
          .replace(/\//g, "")
        return (
          <Link key={node.fields.slug} to={node.fields.slug} id={anchor}>
            <Flex
              sx={{
                flexDirection: "row",
                flexWrap: "wrap",
                alignItems: "flex-start",
                mt: 5,
              }}
            >
              <Box sx={{ width: ["100%", "100%", "33%"], pr: [0, 3], mb: 2 }}>
                <Img
                  fluid={node.frontmatter.image.childImageSharp.fluid}
                  imgStyle={{
                    border: `${node.frontmatter.border}px solid #999`,
                  }}
                ></Img>
              </Box>
              <Box sx={{ width: ["100%", "100%", "67%"], pl: [0, 3] }}>
                <article>
                  <header>
                    <Text sx={{ fontSize: 7 }}>
                      {node.frontmatter.date}
                      <Spacer />
                      {node.frontmatter.pin && (
                        <FontAwesomeIcon
                          icon={["fas", "thumbtack"]}
                          rotation={90}
                        />
                      )}
                    </Text>
                    <Heading as="h2" sx={{ fontSize: 2 }}>
                      {title}
                    </Heading>
                    <Text sx={{ mb: 2 }}>{node.frontmatter.authorName}</Text>
                  </header>
                  <section>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: node.frontmatter.description || node.excerpt,
                      }}
                    />
                  </section>
                </article>
              </Box>
            </Flex>
          </Link>
        )
      })}
    </Layout>
  )
}

export default Blogs

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      filter: { fileAbsolutePath: { regex: "/blog/" } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      group(field: frontmatter___pin) {
        edges {
          node {
            excerpt
            fields {
              slug
            }
            frontmatter {
              pin
              date(formatString: "DD. MMMM YYYY")
              title
              description
              authorName
              authorStatement
              image {
                childImageSharp {
                  fluid(maxWidth: 400, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              border
            }
          }
        }
      }
    }
  }
`
